module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_IN","url":"https://sciencearound.in","site_name":"Beyond 8"},"twitter":{"handle":"@beyondeighth","site":"@beyondeighth","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-theme-blocks/gatsby-browser.js'),
      options: {"plugins":[],"dev":false,"contentPath":"content/pages","partialPath":"content/partials","productionUrl":"https://sciencearound.in"},
    }]
